<template>
  <div class="page-container document-page">
    <portal to="page-name">Modelo de documento</portal>
    <div class="card card-page">
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.active">
            <i class="form-icon"></i> Registro ativo
          </label>
        </div>
        <h1 class="card-title">Modelo de documento</h1>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-8 col-sm-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome</label>
            <input type="text" id="name" name="name"
                   v-model="form.name" class="form-input"
                   @change="$v.form.name.$touch()">
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-4 col-sm-12 form-group">
            <label for="size" class="form-label">Formato para impressão</label>
            <select id="size" name="size" class="form-select"
                    v-model="form.size">
              <option value="A4">A4</option>
              <option value="A5">A5</option>
            </select>
          </div>
          <div class="column col-12 form-group">
            <label for="professional" class="form-label">Compartilhado com</label>
            <select id="professional" name="professional" class="form-select"
                    v-model="professionalId" @change="addProfessional">
              <option value="">Selecione</option>
              <option value="allProfessionals"
                      v-if="filteredProfessionals.length > 1">[Adicionar todos]</option>
              <option v-for="(item, i) in filteredProfessionals"
                      :value="item.id" :key="i">{{ item.name }}</option>
            </select>
            <div class="chip bg-secondary mt-2"
                 v-for="(item, i) in form.professionals"
                 :key="item.id">
              <span>{{ item.name }}</span>
              <button class="btn btn-clear" aria-label="Close"
                      @click="deleteProfessional(item.id, i)" />
            </div>
          </div>
        </div>
        <div class="divider" />
        <div class="btn-group btn-group-block mt-2 mb-2">
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('date')">
            Data atual
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('hour')">
            Hora atual
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('cid')">
            CID-10
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('patient_name')">
            Nome do paciente
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('patient_gender')">
            Sexo
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('patient_birth_date')">
            Data de Nascimento
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('patient_age')">
            Idade
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('patient_cpf')">
            CPF
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('patient_phone')">
            Telefone
          </button>
          <button class="btn btn-sm btn-gray"
                  @click="insertTag('patient_address')">
            Endereço do paciente
          </button>
        </div>
        <st-editor id="document-editor" ref="editorDocument"
                   v-model="form.data"></st-editor>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1" @click="save()"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from '@/mixins/form';
import * as professionalApi from '@/app/professional/api';

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/document-templates',
      isNew: true,
      loading: false,
      saving: false,
      professionalId: '',
      professionals: [],
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    this.loadProfessionals();
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        size: { required },
        active: { required },
      },
    };
  },
  computed: {
    filteredProfessionals() {
      return this.professionals
        .filter(item => this.form.professionals
          .every(professional => professional.id !== item.id));
    },
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          if (data) {
            this.form = data;
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;

      const data = this.clone(this.form);

      const request = !data.id
        ? this.$http.post(this.path, data)
        : this.$http.put(`${this.path}/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          if (this.isNew) {
            this.$router.replace(`${this.path}/${result.id}/edit`);
            this.isNew = false;
            this.form.id = result.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {}).then(() => {
          this.saving = false;
        });
    },
    addProfessional() {
      if (!this.professionalId) {
        return;
      }

      if (this.professionalId === 'allProfessionals') {
        this.form.professionals = this.clone(this.professionals);
      }

      const professional = this.professionals
        .find(item => item.id === this.professionalId);

      if (professional) {
        this.form.professionals
          .push({ id: professional.id, name: professional.name });
      }
      this.professionalId = '';
    },
    deleteProfessional(id, i) {
      if (this.isNew) {
        this.form.professionals.splice(i, 1);
        return false;
      }

      return this.$http
        .delete(`/document-templates/${this.form.id}/professionals/${id}`)
        .then(() => {
          this.form.professionals.splice(i, 1);
        })
        .catch();
    },
    insertTag(name) {
      this.$refs.editorDocument.editor.model.change((writer) => {
        writer.insertText(`[${name}]`,
          this.$refs.editorDocument.editor.model.document.selection.getFirstPosition());
      });
    },
    loadProfessionals() {
      return professionalApi.allActive(true)
        .then((data) => {
          this.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    blankForm() {
      return {
        id: '',
        name: '',
        size: 'A4',
        professionals: [],
        active: true,
        data: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
  .document-page {
    margin-left: auto;
    margin-right: auto;
    max-width: $control-width-lg;
    .document-editor-body {
      .ck.ck-content.ck-editor__editable {
        min-height: 40rem;
        padding: 15px 72px;
      }
    }
  }
</style>
