<template>
  <div class="appointment-print-page">
    <div class="columns">
      <div class="column form-group">
        <label for="filter-search" class="form-label">Pesquisar</label>
        <div class="input-group">
          <input type="text" id="filter-search" class="form-input"
                 v-model="filter.search" maxlength="100"
                 autocomplete="nope">
          <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                  tabindex="-1">
            <fa-icon :icon="['fal', 'search']"/>
          </button>
        </div>
      </div>
      <div class="column col-auto mb-2"
           style="display: flex; align-items: flex-end">
        <button class="btn btn-primary btn-icon btn-icon-left"
                @click="create">
          <fa-icon :icon="['fal', 'plus']"/> Novo impresso
        </button>
      </div>
    </div>
    <div class="loading mt-2" v-if="loading" />
    <template v-else>
      <template v-if="data.items.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Impressos</p>
          <p class="empty-subtitle">
            Nenhum impresso encontrado. Verifique os filtros para realizar novas buscas
          </p>
        </div>
      </template>
      <template v-else>
        <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th class="hide-sm" width="40px">#</th>
            <th>Nome</th>
            <th class="hide-sm hide-md text-center">Ativo</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in data.items" :key="i">
            <td class="hide-sm">{{ i + data.offset + 1 }}</td>
            <td>
              <router-link :to="editLink(item)">
                {{ item.name }}
              </router-link>
            </td>
            <td class="hide-sm hide-md text-center">
              {{ item.active ? 'Sim' : 'Não' }}
            </td>
            <td class="text-right" style="width:80px">
              <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip mr-1"
                      data-tooltip="Editar" @click="edit(item)">
                <fa-icon :icon="['fal', 'pencil']"></fa-icon>
              </button>
              <button class="btn btn-sm btn-action btn-icon btn-error tooltip"
                      :class="{loading: deleting}" data-tooltip="Excluir"
                      :disabled="deleting" @click="remove(item.id, i)">
                <fa-icon :icon="['fal', 'times']"></fa-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="mt-2">
          <st-pagination :total="data.total"
                         :limit="data.limit" @paginate="paginate"></st-pagination>
          <span>Total de registros:
          <span class="ml-2 text-primary text-bold">{{ data.total }}</span>
        </span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { DELETE_TEMPLATE } from '@/data/actions/modules/clinical';

export default {
  data() {
    return {
      path: '/appointment-templates',
      loading: false,
      deleting: false,
      filter: {
        search: '',
      },
      data: {
        items: [],
        limit: 20,
        offset: 0,
      },
    };
  },
  async mounted() {
    this.load();
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.search, 600);
      },
      deep: true,
    },
  },
  computed: {
    canAccessRemoveTemplate() {
      if (DELETE_TEMPLATE) {
        return this.$can(DELETE_TEMPLATE);
      }
      return true;
    },
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      this.loading = true;

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    remove(id, i) {
      if (!this.canAccessRemoveTemplate) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este impresso?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http
                .delete(`${this.path}/${id}`)
                .then(() => {
                  this.data.items.splice(i, 1);
                  this.data.total -= 1;
                })
                .catch(() => {
                  this.$toast.show('Não foi possível excluir', { type: 'error' });
                })
                .then(() => {
                  this.deleting = false;
                });
              close();
            },
          },
        ],
      });
    },
    create() {
      this.$router.push(`${this.path}/create`);
    },
    edit(item) {
      this.$router.push(`${this.path}/${item.id}/edit`);
    },
    editLink(item) {
      return `${this.path}/${item.id}/edit`;
    },
    paginate({ offset }) {
      this.data.offset = offset;
      this.load();
    },
  },
};
</script>

<style lang="scss">
  .appointment-print-page {
  }
</style>
