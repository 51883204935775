<template>
  <div class="page-container templates-page">
    <portal to="page-name">Modelos de impressos</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Modelos de impressos</h1>
      </div>
      <div class="card-body">
        <st-tabs id="template-tabs" ref="template-tabs"
                 @changed="changeTab">
          <st-tab id="tab-anamnesis" name="Anamnese">
            <anamnesis />
          </st-tab>
          <st-tab id="tab-request" name="Solicitação">
            <request />
          </st-tab>
          <st-tab id="tab-prescription" name="Receituário">
            <prescription />
          </st-tab>
          <st-tab id="tab-document" name="Documentos">
            <document />
          </st-tab>
          <st-tab id="tab-report" name="Laudos">
            <report />
          </st-tab>
          <st-tab id="tab-appointment" name="Agendamento">
            <appointment />
          </st-tab>
          <st-tab id="tab-surgery" name="Descrição cirúrgica">
            <surgery></surgery>
          </st-tab>
        </st-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage';
import Anamnesis from './anamnesis/Main.vue';
import Appointment from './appointment/Main.vue';
import Document from './document/Main.vue';
import Prescription from './recipe/Main.vue';
import Report from './report/Main.vue';
import Request from './request/Main.vue';
import Surgery from './surgery/Main.vue';

export default {
  components: {
    Anamnesis,
    Appointment,
    Document,
    Prescription,
    Report,
    Request,
    Surgery,
  },
  mounted() {
    localforage.getItem('template-tab')
      .then((tab) => {
        this.$refs['template-tabs'].selectTab(tab);
      });
  },
  methods: {
    changeTab({ tab }) {
      localforage.setItem('template-tab', tab.hash);
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .templates-page {
  }
</style>
