<template>
  <div class="page-container request-page">
    <portal to="page-name">Modelo de guia de solicitação</portal>
    <div class="card card-page">
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.active">
            <i class="form-icon"></i> Registro ativo
          </label>
        </div>
        <h1 class="card-title">Modelo de guia de solicitação</h1>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-8 col-sm-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome</label>
            <input type="text" id="name" name="name"
                   v-model="form.name" class="form-input"
                   @change="$v.form.name.$touch()">
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-4 col-sm-12 form-group"
               :class="{'has-error': $v.form.type.$error}">
            <label for="type" class="form-label">Tipo</label>
            <select id="type" name="type" class="form-select"
                    v-model="form.type"
                    @change="$v.form.type.$touch()">
              <option value="">Selecione</option>
              <option value="exam">Solicitação de exames clínicos</option>
              <option value="laboratory">Solicitação de exames laboratoriais</option>
              <option value="hospitalization">Solicitação de internação</option>
            </select>
            <template v-if="$v.form.type.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.type.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-12 form-group">
            <label for="professional" class="form-label">Compartilhado com</label>
            <select id="professional" name="professional" class="form-select"
                    v-model="professionalId" @change="addProfessional">
              <option value="">Selecione</option>
              <option value="allProfessionals"
                      v-if="filteredProfessionals.length > 1">[Adicionar todos]</option>
              <option v-for="(item, i) in filteredProfessionals"
                      :value="item.id" :key="i">{{ item.name }}</option>
            </select>
            <div class="chip bg-secondary mt-2"
                 v-for="(item, i) in form.professionals"
                 :key="item.id">
              <span>{{ item.name }}</span>
              <button class="btn btn-clear" aria-label="Close"
                      @click="deleteProfessional(item.id, i)" />
            </div>
          </div>
        </div>
        <div class="mt-2">Exames</div>
        <div class="columns">
          <div class="column col-8">
            <dx-autocomplete
              id="expense"
              ref="expense"
              v-model="expense"
              :source="findExpense"
              label="expense"
              track-by="expense-id"
              @select="setExpense"
              :debounce="800"
              placeholder="Pesquisar por código ou descrição">
              <button slot="action"
                      class="btn btn-neutral btn-action input-group-btn btn-icon">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <template v-slot="{ item }">
              <span>
                <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
                {{ item.name }} ({{ item.code | tuss }})
              </span>
                <span>{{ item.display }}</span>
              </template>
            </dx-autocomplete>
          </div>
        </div>
        <table class="table" v-if="form.expenses.length > 0">
          <thead>
          <tr>
            <th style="width:50px">#</th>
            <th style="width:100px">Código</th>
            <th>Descrição</th>
            <th class="text-center" style="width:90px">Quantidade</th>
            <th style="width:40px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in form.expenses" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ item.code | tuss }}</td>
            <td>
              <input type="text" class="form-input input-sm" v-model="item.name">
            </td>
            <td class="text-center">
              <input
                type="number" class="form-input input-sm"
                v-model="item.quantity" min="1"
              >
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-error"
                      @click="removeExpense(i)">
                <fa-icon :icon="['fal', 'times']"></fa-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <template v-if="form.type === 'hospitalization'">
          <div class="mt-2">OPMs</div>
          <div class="columns">
            <div class="column col-8">
              <dx-autocomplete
                id="opme"
                ref="opme"
                v-model="opme"
                :source="findOpme"
                label="opme"
                track-by="opme-id"
                @select="setOpme"
                :debounce="800"
                placeholder="Pesquisar por código ou descrição">
                <button slot="action"
                        class="btn btn-neutral btn-action input-group-btn btn-icon">
                  <fa-icon :icon="['fal', 'search']"/>
                </button>
                <template v-slot="{ item }">
                  <div>
                    <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
                    {{ item.technicalName }} ({{ item.code | tuss }})
                  </div>
                  <div>{{ item.model }}</div>
                </template>
              </dx-autocomplete>
            </div>
          </div>
          <table class="table" v-if="form.opmes.length > 0">
            <thead>
            <tr>
              <th style="width:50px">#</th>
              <th style="width:100px">Código</th>
              <th>Descrição</th>
              <th class="text-center" style="width:90px">Quantidade</th>
              <th style="width:40px"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in form.opmes" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.code | tuss }}</td>
              <td>
                <input type="text" class="form-input input-sm" v-model="item.name">
              </td>
              <td class="text-center">
                <input
                  type="number" class="form-input input-sm"
                  v-model="item.quantity" min="1"
                >
              </td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-error"
                        @click="removeOpme(i)">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1" @click="save()"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from '@/mixins/form';
import { randomHash } from '@/helpers';
import * as professionalApi from '@/app/professional/api';

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/request-templates',
      isNew: true,
      loading: false,
      saving: false,
      expense: '',
      opme: '',
      professionalId: '',
      professionals: [],
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    this.loadProfessionals();
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
        active: { required },
      },
    };
  },
  computed: {
    filteredProfessionals() {
      return this.professionals
        .filter(item => this.form.professionals
          .every(professional => professional.id !== item.id));
    },
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          if (data) {
            this.form = data;
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;

      const data = this.clone(this.form);

      const request = !data.id
        ? this.$http.post(this.path, data)
        : this.$http.put(`${this.path}/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          if (this.isNew) {
            this.$router.replace(`${this.path}/${result.id}/edit`);
            this.isNew = false;
            this.form.id = result.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    removeExpense(idx) {
      this.form.expenses.splice(idx, 1);
    },
    findExpense(search) {
      const params = {
        types: 'procedure',
        search,
        limit: 50,
      };

      return this.$http.get('/tuss', { params })
        .then(({ data }) => data.items);
    },
    setExpense(data) {
      this.expense = null;
      const found = this.form.expenses.find(item => item.id === data.id);
      if (found) {
        found.quantity += 1;
      } else {
        this.form.expenses.push({
          key: randomHash(),
          id: data.id,
          code: data.code,
          name: data.name,
          quantity: 1,
        });
      }
    },
    removeOpme(idx) {
      this.form.opmes.splice(idx, 1);
    },
    findOpme(search) {
      const params = {
        types: 'material',
        search,
        limit: 50,
      };

      return this.$http.get('/tuss', { params })
        .then(({ data }) => data.items);
    },
    setOpme(data) {
      this.opme = null;
      const found = this.form.opmes.find(item => item.id === data.id);
      if (found) {
        found.quantity += 1;
      } else {
        this.form.opmes.push({
          key: randomHash(),
          id: data.id,
          code: data.code,
          name: [data.technicalName, data.model].filter(v => !!v).join(' '),
          quantity: 1,
        });
      }
    },
    addProfessional() {
      if (!this.professionalId) {
        return;
      }

      if (this.professionalId === 'allProfessionals') {
        this.form.professionals = this.clone(this.professionals);
      }

      const professional = this.professionals
        .find(item => item.id === this.professionalId);

      if (professional) {
        this.form.professionals
          .push({ id: professional.id, name: professional.name });
      }
      this.professionalId = '';
    },
    deleteProfessional(id, i) {
      if (this.isNew) {
        this.form.professionals.splice(i, 1);
        return false;
      }

      return this.$http
        .delete(`/request-templates/${this.form.id}/professionals/${id}`)
        .then(() => {
          this.form.professionals.splice(i, 1);
        })
        .catch();
    },
    loadProfessionals() {
      return professionalApi.allActive(true)
        .then((data) => {
          this.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    blankForm() {
      return {
        id: '',
        name: '',
        type: '',
        professionals: [],
        expenses: [],
        opmes: [],
        active: true,
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
  .request-page {
    margin-left: auto;
    margin-right: auto;
    max-width: $control-width-lg;
    .card-body {
      .table {
        margin-bottom: $layout-spacing-lg;
        tbody tr {
          background-color: $gray-color-light;
        }
        td {
          border-bottom-color: $bg-color;
        }
      }
    }
  }
</style>
