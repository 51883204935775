<template>
  <div class="page-container appointment-print-form-page">
    <portal to="page-name">Modelo de impresso</portal>
    <div class="card card-page">
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.active">
            <i class="form-icon"></i> Registro ativo
          </label>
        </div>
        <h1 class="card-title">Modelo de impresso</h1>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-8 col-sm-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome</label>
            <input type="text" id="name" name="name"
                   v-model="form.name" class="form-input"
                   @change="$v.form.name.$touch()">
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
        </div>
        <div class="divider" />
        <div class="mt-2 mb-2">
          <button
            class="btn btn-sm mr-1 mb-1 btn-info-outline"
            v-for="tag in tags"
            :key="tag.key"
            @click="insertTag(tag.key)"
          >{{ tag.value }}</button>
        </div>
        <st-editor id="appointment-editor" ref="editorAppointment"
                   v-model="form.data"></st-editor>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1" @click="save()"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from '@/mixins/form';

const tags = [
  { key: 'appointment_date', value: 'Data do agendamento' },
  { key: 'appointment_hour', value: 'Hora do agendamento' },
  { key: 'current_date', value: 'Data atual' },
  { key: 'current_hour', value: 'Hora atual' },
  { key: 'patient_name', value: 'Paciente' },
  { key: 'patient_gender', value: 'Sexo' },
  { key: 'patient_birth_date', value: 'Data de nascimento' },
  { key: 'patient_age', value: 'Idade' },
  { key: 'patient_cpf', value: 'CPF' },
  { key: 'patient_cellphone', value: 'Celular' },
  { key: 'patient_phone', value: 'Telefone' },
  { key: 'patient_email', value: 'Email' },
  { key: 'patient_address', value: 'Endereço' },
  { key: 'professional', value: 'Profissional' },
  { key: 'insurance', value: 'Convênio' },
  { key: 'expenses', value: 'Procedimento' },
  { key: 'indicated', value: 'Indicado por' },
];

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/surgery-templates',
      isNew: true,
      loading: false,
      saving: false,
      form: this.blankForm(),
      tags,
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        active: { required },
      },
    };
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          if (data) {
            this.form = data;
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;

      const data = this.clone(this.form);

      const request = !data.id
        ? this.$http.post(this.path, data)
        : this.$http.put(`${this.path}/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          if (this.isNew) {
            this.$router.replace(`${this.path}/${result.id}/edit`);
            this.isNew = false;
            this.form.id = result.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    insertTag(name) {
      this.$refs.editorAppointment.editor.model.change((writer) => {
        writer.insertText(`[${name}]`,
          this.$refs.editorAppointment.editor.model.document.selection.getFirstPosition());
      });
    },
    blankForm() {
      return {
        id: '',
        name: '',
        active: true,
        data: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
  .appointment-print-form-page {
    margin-left: auto;
    margin-right: auto;
    max-width: $control-width-lg;
    .document-editor-body {
      .ck.ck-content.ck-editor__editable {
        min-height: 40rem;
        padding: 15px 72px;
      }
    }
  }
</style>
